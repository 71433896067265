import React from 'react';
import './blogCard.css';
import { Link } from 'react-router-dom'
import InfoButton from '../../../button/infoButton/InfoButton';
import { blogDetailsImages } from '../../blogDetails/blogDetailsImageData';

const BlogCard = ({ title, date, description, id }) => {
  const blogImages = blogDetailsImages[id];

  return (
    <div className="blogCard">
      <div className="blogCard-image" style={{ backgroundImage: `url(${blogImages[1]})` }} />
      <div className="blogCard-content">
        <h2 className="blogCard-title">{title}</h2>
        <div className="blogCard-date">{date}</div>
        <div className="blogCard-description">{description}</div>
        <InfoButton text='Pročitaj više' link={`/blogs/${id}`}></InfoButton>
      </div>
    </div>
  );
};

export default BlogCard;
