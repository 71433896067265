import React, { useState } from "react";
import "./searchBar.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SearchBar = (props) => {
  const [minPrice, setMinPrice] = useState(20000);
  const [maxPrice, setMaxPrice] = useState(50000);
  const [minSqft, setMinSqft] = useState(20);
  const [maxSqft, setMaxSqft] = useState(30);
  const [floor, setFloor] = useState("Any");
  const [type, setType] = useState("Any");
  
  //handler for disabling min value go over max value

  const handleMinPriceChange = (e) => {
    const newMinPrice = parseInt(e.target.value, 10);
    if (newMinPrice > maxPrice) {
      setMaxPrice(newMinPrice);
    }
    setMinPrice(newMinPrice);

  };

  const handleMaxPriceChange = (e) => {
    const newMaxPrice = parseInt(e.target.value, 10);
    if (newMaxPrice < minPrice) {
      setMinPrice(newMaxPrice);
    }
    setMaxPrice(newMaxPrice);


  };

  const handleMinSqftChange = (e) => {
    const newMinSqft = parseInt(e.target.value, 10);
    if (newMinSqft > maxSqft) {
      setMaxSqft(newMinSqft);
    }
    setMinSqft(newMinSqft);
  };

  const handleMaxSqftChange = (e) => {
    const newMaxSqft = parseInt(e.target.value, 10);
    if (newMaxSqft < minSqft) {
      setMinSqft(newMaxSqft);
    }
    setMaxSqft(newMaxSqft);
  };
  
  //sending data as props back to parent component
  const handleSearchButtonClick = () => {
    props.onMinPriceChange(minPrice);
    props.onMaxPriceChange(maxPrice); 
    props.onMinSqftChange(minSqft);
    props.onMaxSqftChange(maxSqft); 
    props.onFloorChange(floor);
    props.onTypeChange(type);
    
    const div = document.getElementById("search-button");
    if (div) {
      div.scrollIntoView({
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className='container'>
      <div className="search-container">
          <div className="price-slider">
          <div className="slider-title">
            Cena: {minPrice}€ - {maxPrice}€
          </div>
          <input className="slider-input"
            type="range"
            min={20000}
            max={50000}
            value={minPrice}
            onChange={handleMinPriceChange}        
          />
          <input className="slider-input"
            type="range"
            min={20000}
            max={50000}
            value={maxPrice}
            onChange={handleMaxPriceChange}        />
        </div>
        
      <div className="sqft-slider">
          <div className="slider-title">
            Kvadratura: {minSqft}m² - {maxSqft}m²
          </div>
          <input className="slider-input"
            type="range"
            min={20}
            max={30}
            value={minSqft}
            onChange={handleMinSqftChange}        
          />
          <input className="slider-input"
            type="range"
            min={20}
            max={30}
            value={maxSqft}
            onChange={handleMaxSqftChange}        
          />
        </div>
        <div className="floor-dropdown">
        <div className="slider-title">
          Sprat:
        </div>
        <select className="slider-select" value={floor} onChange={(e) => setFloor(e.target.value)}>
          <option value="Any">bilo koji</option>
          <option value="Podrum">podrum</option>
          <option value="Suteren">suteren</option>
          <option value="Prizemlje">prizemlje</option>
          <option value="Potkrovlje">potkrovlje</option>
        </select>
      </div>
      <div className="type-dropdown">
      <div className="slider-title">
          Tip:
        </div>
        <select className="slider-select" value={type} onChange={(e) => setType(e.target.value)}>
          <option value="Any">bilo koji</option>
          <option value="0">studio</option>
          <option value="1">jednosobni</option>
        </select>
      </div>
      
      </div>
      <button id="search-button" className="search-button" onClick={handleSearchButtonClick}>
        <FontAwesomeIcon icon={faSearch} />
      </button>  
    </div>
    
  );
};

export default SearchBar;