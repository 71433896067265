export const floorsTypeData = [
  {id: '1', name: 'a',          frontalImageName: 'a_floor',          numberOfApartments: '3'},
  {id: '2', name: 'a_ground',   frontalImageName: 'a_floor_ground',   numberOfApartments: '3'},
  {id: '3', name: 'a_loft',     frontalImageName: 'a_floor_loft',     numberOfApartments: '3'},
  {id: '4', name: 'a_basement', frontalImageName: 'a_floor_ground',   numberOfApartments: '3'},
];

export const floorsData = [
  { id: '1',  name:'Objekat A1 - podrum',     label: 'Podrum',     sold: '0', reserved: '0', buildingId: '1', floorTypeId: '4'},
  { id: '2',  name:'Objekat A1 - suteren',    label: 'Suteren',    sold: '1', reserved: '0', buildingId: '1', floorTypeId: '2'},
  { id: '3',  name:'Objekat A1 - prizemlje',  label: 'Prizemlje',  sold: '1', reserved: '0', buildingId: '1', floorTypeId: '1'},
  { id: '4',  name:'Objekat A1 - potkrovlje', label: 'Potkrovlje', sold: '1', reserved: '0', buildingId: '1', floorTypeId: '3'},
  
  { id: '5',  name:'Objekat A2 - podrum',     label: 'Podrum',     sold: '0', reserved: '0', buildingId: '2', floorTypeId: '4'},
  { id: '6',  name:'Objekat A2 - suteren',    label: 'Suteren',    sold: '1', reserved: '0', buildingId: '2', floorTypeId: '2'},
  { id: '7',  name:'Objekat A2 - prizemlje',  label: 'Prizemlje',  sold: '1', reserved: '0', buildingId: '2', floorTypeId: '1'},
  { id: '8',  name:'Objekat A2 - potkrovlje', label: 'Potkrovlje', sold: '1', reserved: '0', buildingId: '2', floorTypeId: '3'},
  
  { id: '9',   name:'Objekat A3 - podrum',     label: 'Podrum',     sold: '1', reserved: '0', buildingId: '3', floorTypeId: '4'},
  { id: '10',  name:'Objekat A3 - suteren',    label: 'Suteren',    sold: '1', reserved: '0', buildingId: '3', floorTypeId: '2'},
  { id: '11',  name:'Objekat A3 - prizemlje',  label: 'Prizemlje',  sold: '1', reserved: '0', buildingId: '3', floorTypeId: '1'},
  { id: '12',  name:'Objekat A3 - potkrovlje', label: 'Potkrovlje', sold: '1', reserved: '0', buildingId: '3', floorTypeId: '3'},
  
  { id: '13',  name:'Objekat B1 - podrum',     label: 'Podrum',     sold: '0', reserved: '0', buildingId: '4', floorTypeId: '4'},
  { id: '14',  name:'Objekat B1 - suteren',    label: 'Suteren',    sold: '0', reserved: '0', buildingId: '4', floorTypeId: '2'},
  { id: '15',  name:'Objekat B1 - prizemlje',  label: 'Prizemlje',  sold: '0', reserved: '0', buildingId: '4', floorTypeId: '1'},
  { id: '16',  name:'Objekat B1 - potkrovlje', label: 'Potkrovlje', sold: '0', reserved: '0', buildingId: '4', floorTypeId: '3'},
  
  { id: '17',  name:'Objekat B2 - podrum',     label: 'Podrum',     sold: '0', reserved: '0', buildingId: '5', floorTypeId: '4'},
  { id: '18',  name:'Objekat B2 - suteren',    label: 'Suteren',    sold: '0', reserved: '0', buildingId: '5', floorTypeId: '2'},
  { id: '19',  name:'Objekat B2 - prizemlje',  label: 'Prizemlje',  sold: '0', reserved: '0', buildingId: '5', floorTypeId: '1'},
  { id: '20',  name:'Objekat B2 - potkrovlje', label: 'Potkrovlje', sold: '0', reserved: '0', buildingId: '5', floorTypeId: '3'},
 
  { id: '21',  name:'Objekat B3 - podrum',     label: 'Podrum',     sold: '0', reserved: '0', buildingId: '6', floorTypeId: '4'},
  { id: '22',  name:'Objekat B3 - suteren',    label: 'Suteren',    sold: '0', reserved: '0', buildingId: '6', floorTypeId: '2'},
  { id: '23',  name:'Objekat B3 - prizemlje',  label: 'Prizemlje',  sold: '0', reserved: '0', buildingId: '6', floorTypeId: '1'},
  { id: '24',  name:'Objekat B3 - potkrovlje', label: 'Potkrovlje', sold: '0', reserved: '0', buildingId: '6', floorTypeId: '3'}
];