//images for blog1
import blog1DetailsImage1 from '../../../assets/blog/1/1.jpg'
import blog1DetailsImage2 from '../../../assets/blog/1/2.jpg'
import blog1DetailsImage3 from '../../../assets/blog/1/3.jpg'
import blog1DetailsImage4 from '../../../assets/blog/1/4.jpg'
import blog1DetailsImage5 from '../../../assets/blog/1/5.jpg'

//images for blog2
import blog2DetailsImage1 from '../../../assets/blog/2/1.png'
import blog2DetailsImage2 from '../../../assets/blog/2/2.jpg'

//images for blog3
import blog3DetailsImage1 from '../../../assets/blog/3/1.jpg'
import blog3DetailsImage2 from '../../../assets/blog/3/2.jpg'
import blog3DetailsImage3 from '../../../assets/blog/3/3.jpg'
import blog3DetailsImage4 from '../../../assets/blog/3/4.jpg'

//images for blog4
import blog4DetailsImage1 from '../../../assets/blog/4/1.jpg'
import blog4DetailsImage2 from '../../../assets/blog/4/2.jpg'
import blog4DetailsImage3 from '../../../assets/blog/4/3.jpg'
import blog4DetailsImage4 from '../../../assets/blog/4/4.jpg'
import blog4DetailsImage5 from '../../../assets/blog/4/5.jpg'
import blog4DetailsImage6 from '../../../assets/blog/4/6.jpg'
import blog4DetailsImage7 from '../../../assets/blog/4/7.jpg'
import blog4DetailsImage8 from '../../../assets/blog/4/8.jpg'
import blog4DetailsImage9 from '../../../assets/blog/4/9.jpg'
import blog4DetailsImage10 from '../../../assets/blog/4/10.jpg'
import blog4DetailsImage11 from '../../../assets/blog/4/11.jpg'
import blog4DetailsImage12 from '../../../assets/blog/4/12.jpg'
import blog4DetailsImage13 from '../../../assets/blog/4/13.jpg'

//images for blog1 details page
export const blog1DetailsImages = {
    1: blog1DetailsImage1,
    2: blog1DetailsImage2,
    3: blog1DetailsImage3,
    4: blog1DetailsImage4,
    5: blog1DetailsImage5
  };
  
//images for blog2 details page
export const blog2DetailsImages = {
    1: blog2DetailsImage1,
    2: blog2DetailsImage2
  };
      
//images for blog3 details page
export const blog3DetailsImages = {
  1: blog3DetailsImage1,
  2: blog3DetailsImage2,
  3: blog3DetailsImage3,
  4: blog3DetailsImage4
};
  
//images for blog4 details page
export const blog4DetailsImages = {
  1: blog4DetailsImage1,
  2: blog4DetailsImage2,
  3: blog4DetailsImage3,
  4: blog4DetailsImage4,
  5: blog4DetailsImage5,
  6: blog4DetailsImage6,
  7: blog4DetailsImage7,
  8: blog4DetailsImage8,
  9: blog4DetailsImage9,
  10: blog4DetailsImage10,
  11: blog4DetailsImage11,
  12: blog4DetailsImage12,
  13: blog4DetailsImage13
};

//list of lists of all blog details image pages  
export const blogDetailsImages = {
    1: blog1DetailsImages,
    2: blog2DetailsImages,
    3: blog3DetailsImages,
    4: blog4DetailsImages
  };