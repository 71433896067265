import React from 'react';
import './citationCard.css';

const CitationCard = ({ title, text, name }) => {
  return (
    <div className="citation-card">
        <h2 className="citation-title">{title}</h2>
        <p className="citation-text">{text}</p>
        <p className="citation-name">{name}</p>
    </div>
  );
};

export default CitationCard;
