import hero from "../../assets/hero.png"
import hero2 from "../../assets/hero2.jpg"
import hero2_skewed from "../../assets/hero2-skewed.png"

//images for buildings
import a_buildings  from  "../../assets/arch_project/building_complex/a.png";
import a_building1_gray from "../../assets/arch_project/building_complex/a1_g.png";
import a_building2_gray from "../../assets/arch_project/building_complex/a2_g.png";
import a_building3_gray from "../../assets/arch_project/building_complex/a3_g.png";
import a_building4_gray from "../../assets/arch_project/building_complex/a4_g.png";
import a_building5_gray from "../../assets/arch_project/building_complex/a5_g.png";
import a_building6_gray from "../../assets/arch_project/building_complex/a6_g.png";
import a_building1_yellow from "../../assets/arch_project/building_complex/a1_y.png";
import a_building2_yellow from "../../assets/arch_project/building_complex/a2_y.png";
import a_building3_yellow from "../../assets/arch_project/building_complex/a3_y.png";
import a_building4_yellow from "../../assets/arch_project/building_complex/a4_y.png";
import a_building5_yellow from "../../assets/arch_project/building_complex/a5_y.png";
import a_building6_yellow from "../../assets/arch_project/building_complex/a6_y.png";
import a_building1_red from "../../assets/arch_project/building_complex/a1_r.png";
import a_building2_red from "../../assets/arch_project/building_complex/a2_r.png";
import a_building3_red from "../../assets/arch_project/building_complex/a3_r.png";
import a_building4_red from "../../assets/arch_project/building_complex/a4_r.png";
import a_building5_red from "../../assets/arch_project/building_complex/a5_r.png";
import a_building6_red from "../../assets/arch_project/building_complex/a6_r.png";


//images for floors
import a_building from "../../assets/arch_project/buildings/a.png";
import a_ground_red from "../../assets/arch_project/buildings/a_ground_r.png";
import a_basement_red from "../../assets/arch_project/buildings/a_basement_r.png";
import a_red from "../../assets/arch_project/buildings/a_r.png";
import a_loft_red from "../../assets/arch_project/buildings/a_loft_r.png";
import a_ground_gray from "../../assets/arch_project/buildings/a_ground_g.png";
import a_basement_gray from "../../assets/arch_project/buildings/a_basement_g.png";
import a_gray from "../../assets/arch_project/buildings/a_g.png";
import a_loft_gray from "../../assets/arch_project/buildings/a_loft_g.png";
import a_ground_yellow from "../../assets/arch_project/buildings/a_ground_y.png";
import a_basement_yellow from "../../assets/arch_project/buildings/a_basement_y.png";
import a_yellow from "../../assets/arch_project/buildings/a_y.png";
import a_loft_yellow from "../../assets/arch_project/buildings/a_loft_y.png";


//images for apartments
import a_floor_render from "../../assets/arch_project/floors/renders/a.png";
import a_floor_ground_render from "../../assets/arch_project/floors/renders/a_ground.png";
import a_floor_loft_render from "../../assets/arch_project/floors/renders/a_loft.png";

import a_left_hover_red from "../../assets/arch_project/apartments/render_positions/a_left/r.svg";
import a_left_hover_gray from "../../assets/arch_project/apartments/render_positions/a_left/g.svg";
import a_left_hover_yellow from "../../assets/arch_project/apartments/render_positions/a_left/y.svg";
import a_central_hover_red from "../../assets/arch_project/apartments/render_positions/a_central/r.svg";
import a_central_hover_gray from "../../assets/arch_project/apartments/render_positions/a_central/g.svg";
import a_central_hover_yellow from "../../assets/arch_project/apartments/render_positions/a_central/y.svg";
import a_right_hover_red from "../../assets/arch_project/apartments/render_positions/a_right/r.svg";
import a_right_hover_gray from "../../assets/arch_project/apartments/render_positions/a_right/g.svg";
import a_right_hover_yellow from "../../assets/arch_project/apartments/render_positions/a_right/y.svg";

import a_left_loft_hover_red from "../../assets/arch_project/apartments/render_positions/a_left_loft/r.svg";
import a_left_loft_hover_gray from "../../assets/arch_project/apartments/render_positions/a_left_loft/g.svg";
import a_left_loft_hover_yellow from "../../assets/arch_project/apartments/render_positions/a_left_loft/y.svg";
import a_central_loft_hover_red from "../../assets/arch_project/apartments/render_positions/a_central_loft/r.svg";
import a_central_loft_hover_gray from "../../assets/arch_project/apartments/render_positions/a_central_loft/g.svg";
import a_central_loft_hover_yellow from "../../assets/arch_project/apartments/render_positions/a_central_loft/y.svg";
import a_right_loft_hover_red from "../../assets/arch_project/apartments/render_positions/a_right_loft/r.svg";
import a_right_loft_hover_gray from "../../assets/arch_project/apartments/render_positions/a_right_loft/g.svg";
import a_right_loft_hover_yellow from "../../assets/arch_project/apartments/render_positions/a_right_loft/y.svg";


//images for apartment info 
import a_left_render from "../../assets/arch_project/apartments/renders/a_left.png"
import a_left_loft_render from "../../assets/arch_project/apartments/renders/a_left_loft.png"
import a_left_rooms from "../../assets/arch_project/apartments/rooms/a_left.svg"
import a_left_level_position from "../../assets/arch_project/apartments/level_position/a_left.svg"
import a_central_render from "../../assets/arch_project/apartments/renders/a_central.png"
import a_central_loft_render from "../../assets/arch_project/apartments/renders/a_central_loft.png"
import a_central_rooms from "../../assets/arch_project/apartments/rooms/a_central.svg"
import a_central_level_position from "../../assets/arch_project/apartments/level_position/a_central.svg"
import a_right_render from "../../assets/arch_project/apartments/renders/a_right.png"
import a_right_loft_render from "../../assets/arch_project/apartments/renders/a_right_loft.png"
import a_right_rooms from "../../assets/arch_project/apartments/rooms/a_right.svg"
import a_right_level_position from "../../assets/arch_project/apartments/level_position/a_right.svg"

export const heroImage = {
  hero,
  hero2,
  hero2_skewed
};

export const buildingImages = {
    a_buildings,
    a_building1_gray,
    a_building2_gray,
    a_building3_gray,
    a_building4_gray,
    a_building5_gray,
    a_building6_gray,
    a_building1_yellow,
    a_building2_yellow,
    a_building3_yellow,
    a_building4_yellow,
    a_building5_yellow,
    a_building6_yellow,
    a_building1_red,
    a_building2_red,
    a_building3_red,
    a_building4_red,
    a_building5_red,
    a_building6_red
};

export const floorImages = {
  a_building,
  a_ground_red,
  a_basement_red,
  a_red,
  a_loft_red,
  a_ground_gray,
  a_basement_gray,
  a_gray,
  a_loft_gray,
  a_ground_yellow,
  a_basement_yellow,
  a_yellow,
  a_loft_yellow
};

export const apartmentImages = {
  a_floor_render,
  a_floor_ground_render,
  a_floor_loft_render,
  a_left_hover_red,
  a_left_hover_gray,
  a_left_hover_yellow,
  a_central_hover_red,
  a_central_hover_gray,
  a_central_hover_yellow,
  a_right_hover_red,
  a_right_hover_gray,
  a_right_hover_yellow,
  a_left_loft_hover_red,
  a_left_loft_hover_gray,
  a_left_loft_hover_yellow,
  a_central_loft_hover_red,
  a_central_loft_hover_gray,
  a_central_loft_hover_yellow,
  a_right_loft_hover_red,
  a_right_loft_hover_gray,
  a_right_loft_hover_yellow
};

export const apartmentInfoImages = {
  a_left_render,
  a_left_loft_render,
  a_left_rooms,
  a_left_level_position,
  a_central_render,
  a_central_loft_render,
  a_central_rooms,
  a_central_level_position,
  a_right_render,
  a_right_loft_render,
  a_right_rooms,
  a_right_level_position
}
