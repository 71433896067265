import React, { useEffect } from "react"
import { heroImage, buildingImages } from "../imageData/imageData"
import location from "../../assets/location.png"
import { apartmentsData, apartmentsTypeData } from "../pages/apartmentSelection/apartmentData"
import { buildingsData } from "../pages/buildingSelection/buildingData"
import { floorsData } from "../pages/floorSelection/floorData"
import './home.css'
import Hero from "../common/hero/Hero"
import HomeParagraph from "./homeParagraph/homeParagraph"
import BlogHome from "../pages/blog/blogHome/BlogHome"
import ApartmentCard from "../pages/apartmentsOffer/apartmentCard/ApartmentCard"
import { Link } from "react-router-dom"

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //inner joining apartmentsData, apartmentsTypeData, floorData and Building Data
  const finalData = apartmentsData.filter(apartment => apartment.sold === '0' && apartment.reserved === '0')
  .map(apartment => {
      const floor = floorsData.find(floor => floor.id === apartment.floorId);
      const building = buildingsData.find(building => building.id === floor.buildingId);
      const apartmentType = apartmentsTypeData.find(apartmentType => apartmentType.id === apartment.apartmentTypeId);
     // Add a condition to check if building.buildingTypeId is equal to '1'
     if (building.buildingTypeId === '1') {
      const apartmentType = apartmentsTypeData.find(apartmentType => apartmentType.id === apartment.apartmentTypeId);

      return {
        apartmentId: apartment.id,
        floorId: floor.id,
        floorLabel: floor.label,
        buildingId: building.id,
        buildingLabel: building.label,
        apartmentTypeLabel: apartmentType.label,
        apartmentTypeName: apartmentType.name,
        apartmentNumberOfRooms: apartmentType.numberOfRooms,
        apartmentTypeSquareFootage: apartmentType.squareFootage,
      };
    }
    return null; // Return null for non-matching buildingTypeId
  }).filter(item => item !== null); // Filter out null values

  //function to get random items from an array
  function getRandomItemsFromArray(array, count) {
    const shuffledArray = array.slice().sort(() => Math.random() - 0.5);
    return shuffledArray.slice(0, count);
  }

  //get random three rows from finalData
  const randomThreeRows = getRandomItemsFromArray(finalData, 3);

  return (
    <>
      <Hero  title='IZGRADNJA JE POČELA!' subtitle='APARTMANI NA KOPAONIKU U BLIZINI VRHA TRESKA'  cover={heroImage['hero']} buttonText='IZABERITE SVOJ APARTMAN'  />
      <HomeParagraph title={'Predstavljamo Vam - ROYAL RESORT!'} text={'Upoznajte Royal Resort - kompleks od 72 apartmana smeštenih na planini Kopaonik, nedaleko od vrha Treska. Ova očaravajuća ponuda pruža Vam priliku da postanete ponosni vlasnik luksuznih apartmana okruženih planinskim lepotama, te da istovremeno uživate u raznovrsnim aktivnostima koje ova destinacija nudi. Savršeno mesto za udobnost, luksuz i svež planinski vazduh.'} buttonText={'POGLEDAJ DETALJE O PROJEKTU'} linkTo={'/project'} left={true}/>
      <div className="hero2-image">
        <img src={heroImage['hero2_skewed']}></img>
      </div>
      <HomeParagraph title={'Na odličnoj lokaciji'} text={'Royal Resort se ističe svojom izvanrednom pozicijom. Smešten u neposrednoj blizini vrha Treska, 10 minuta vožnje od centra i 7 minuta vožnje od staze Sunčana Dolina, ovaj kompleks nudi izvanredan pristup raznim sadržajima. Nalazi se na udaljenosti od samo 2 minuta vožnje od početne stanice ski lifta Treska, pružajući udobnost i praktičnost gostima koji žele brz i jednostavan pristup skijalištima. Ova idealna lokacija omogućava Vam da uživate u miru i tišini daleko od gužve, dok istovremeno imate blizinu ski staza za nezaboravno iskustvo na planini.'} buttonText={'PRONAĐI NAS NA MAPI'} linkTo={'/contact'} left={false}/>
      <div className="location">
        <img src={location}/>
        <div className="location-legend">
          <div className="legend-row">
            <i class="fa-solid fa-1"></i>
            <p>Royal Resort</p>
          </div>
          <div className="legend-row">
            <i class="fa-solid fa-2"></i>
            <p>Kopaonik centar</p>
          </div>
          <div className="legend-row">
            <i class="fa-solid fa-3"></i>
            <p>Pančićev vrh</p>
          </div>
          <div className="legend-row">
            <i class="fa-solid fa-4"></i>
            <p>Treska vrh</p>
          </div>
          <div className="legend-row">
            <i class="fa-solid fa-5"></i>
            <p>Početna stanica ski lifta Treska</p>
          </div>
          <div className="legend-row">
            <i class="fa-solid fa-6"></i>
            <p>Početna stanica ski lifta Sunčana Dolina</p>
          </div>
        </div>
      </div>
      <HomeParagraph title={'Prodaja apartmana u izgradnji'} text={'Prodaja apartmana u izgradnji predstavlja izvanrednu priliku za Vas. Naša ponuda obuhvata studio i jednosobne apartmane različitih nivoa, sve po pristupačnim cenama. Sa garantovanim poštovanjem dogovora, Vaše investicije su sigurne. Nemojte propustiti priliku – pogledajte kompletnu ponudu i izaberite svoj budući apartman već danas!'} buttonText={'POGLEDAJ KOMPLETNU PONUDU'} linkTo={'/search'} left={true}/>
      <div className="container">
        <div className="apartmentCard-container">
          {randomThreeRows.map(apartment => (
                        <>
                        <Link to={`/apartment/${apartment.apartmentId}`}>                            
                            <ApartmentCard 
                                imageName={apartment.apartmentTypeName} 
                                type={apartment.apartmentTypeLabel}
                                floorLabel={apartment.floorLabel}
                                buildingLabel={apartment.buildingLabel}
                                squareFootage={apartment.apartmentTypeSquareFootage} >       
                            </ApartmentCard>
                        </Link>
                        </>
                    ))}
        </div>
      </div>
      <HomeParagraph title={'Izaberite svoj apartman'} text={'Izaberite svoj apartman i ostvarite svoj san o savršenom planinskom domu. Naša ponuda obuhvata raznovrsne opcije koje će zadovoljiti sve Vaše potrebe. Koristeći naš vizuelni vodič, jednostavno odaberite objekat i sprat, i pronađite svoj idealan apartman koji će Vam pružiti udobnost i luksuz koji zaslužujete.'} buttonText={'IZABERI SVOJ APARTMAN'} linkTo={'/project/buildings'} left={false}/>
      <div className="building-complex-image">
        <img src={buildingImages['a_buildings']}></img>
      </div>
      <HomeParagraph title={'Pratite najnovije vesti na našem blogu'} text={'Budite u toku sa najnovijim vestima na našem blogu. Tamo ćete pronaći sve informacije o napretku gradnje apartmana i raznim drugim zanimljivostima vezanim za život i boravak na planini Kopaonik. Posetite naš blog i saznajte više o tome kako se odvija gradnja, kao i druge interesantne detalje.'} buttonText={'POSETI BLOG'} linkTo={'/blog'} left={true}/>
      


      <BlogHome displayCount={2}></BlogHome>

    </>
  )
}

export default Home
