export const apartmentsTypeData = [
  { id: '1', name:'a_left',    label: 'Jednosobni apartman', ordinalNumber:'1', squareFootage:'28.47', numberOfRooms:'1', description:'Udoban apartman za porodicu' },
  { id: '2', name:'a_central', label: 'Studio apartman',     ordinalNumber:'2', squareFootage:'20.02', numberOfRooms:'0', description:'Udoban apartman za bračni par' },
  { id: '3', name:'a_right',   label: 'Jednosobni apartman', ordinalNumber:'3', squareFootage:'28.47', numberOfRooms:'1', description:'Udoban apartman za porodicu' },
  { id: '4', name:'a_left_loft',    label: 'Jednosobni apartman', ordinalNumber:'1', squareFootage:'28.47', numberOfRooms:'1', description:'Udoban apartman za porodicu' },
  { id: '5', name:'a_central_loft', label: 'Studio apartman',     ordinalNumber:'2', squareFootage:'20.02', numberOfRooms:'0', description:'Udoban apartman za bračni par' },
  { id: '6', name:'a_right_loft',   label: 'Jednosobni apartman', ordinalNumber:'3', squareFootage:'28.47', numberOfRooms:'1', description:'Udoban apartman za porodicu' },
];

export const apartmentsData = [
  { id: '1',   name:'a_left',    label: 'Apartman 1', price: '1100', sold:'1', reserved:'0', floorId: '1', apartmentTypeId: '1'},
  { id: '2',   name:'a_central', label: 'Apartman 2', price: '1100', sold:'0', reserved:'0', floorId: '1', apartmentTypeId: '2'},
  { id: '3',   name:'a_right',   label: 'Apartman 3', price: '1100', sold:'1', reserved:'0', floorId: '1', apartmentTypeId: '3'},

  { id: '4',   name:'a_left',    label: 'Apartman 4', price: '1200', sold:'1', reserved:'0', floorId: '2', apartmentTypeId: '1'},
  { id: '5',   name:'a_central', label: 'Apartman 5', price: '1200', sold:'1', reserved:'0', floorId: '2', apartmentTypeId: '2'},
  { id: '6',   name:'a_right',   label: 'Apartman 6', price: '1200', sold:'1', reserved:'0', floorId: '2', apartmentTypeId: '3'},

  { id: '7',   name:'a_left',    label: 'Apartman 7', price: '1300', sold:'1', reserved:'0', floorId: '3', apartmentTypeId: '1'},
  { id: '8',   name:'a_central', label: 'Apartman 8', price: '1300', sold:'1', reserved:'0', floorId: '3', apartmentTypeId: '2'},
  { id: '9',   name:'a_right',   label: 'Apartman 9', price: '1300', sold:'1', reserved:'0', floorId: '3', apartmentTypeId: '3'},

  { id: '10',  name:'a_left',    label: 'Apartman 10', price: '1400', sold:'1', reserved:'0', floorId: '4', apartmentTypeId: '4'},
  { id: '11',  name:'a_central', label: 'Apartman 11', price: '1400', sold:'1', reserved:'0', floorId: '4', apartmentTypeId: '5'},
  { id: '12',  name:'a_right',   label: 'Apartman 12', price: '1400', sold:'1', reserved:'0', floorId: '4', apartmentTypeId: '6'},


  { id: '13',  name:'a_left',    label: 'Apartman 1', price: '1100', sold:'1', reserved:'0', floorId: '5', apartmentTypeId: '1'},
  { id: '14',  name:'a_central', label: 'Apartman 2', price: '1100', sold:'0', reserved:'0', floorId: '5', apartmentTypeId: '2'},
  { id: '15',  name:'a_right',   label: 'Apartman 3', price: '1100', sold:'1', reserved:'0', floorId: '5', apartmentTypeId: '3'},

  { id: '16',  name:'a_left',    label: 'Apartman 4', price: '1200', sold:'1', reserved:'0', floorId: '6', apartmentTypeId: '1'},
  { id: '17',  name:'a_central', label: 'Apartman 5', price: '1200', sold:'1', reserved:'0', floorId: '6', apartmentTypeId: '2'},
  { id: '18',  name:'a_right',   label: 'Apartman 6', price: '1200', sold:'1', reserved:'0', floorId: '6', apartmentTypeId: '3'},

  { id: '19',  name:'a_left',    label: 'Apartman 7', price: '1300', sold:'1', reserved:'0', floorId: '7', apartmentTypeId: '1'},
  { id: '20',  name:'a_central', label: 'Apartman 8', price: '1300', sold:'1', reserved:'0', floorId: '7', apartmentTypeId: '2'},
  { id: '21',  name:'a_right',   label: 'Apartman 9', price: '1300', sold:'1', reserved:'0', floorId: '7', apartmentTypeId: '3'},

  { id: '22',  name:'a_left',    label: 'Apartman 10', price: '1400', sold:'1', reserved:'0', floorId: '8', apartmentTypeId: '4'},
  { id: '23',  name:'a_central', label: 'Apartman 11', price: '1400', sold:'1', reserved:'0', floorId: '8', apartmentTypeId: '5'},
  { id: '24',  name:'a_right',   label: 'Apartman 12', price: '1400', sold:'1', reserved:'0', floorId: '8', apartmentTypeId: '6'},


  { id: '25',  name:'a_left',    label: 'Apartman 1', price: '1100', sold:'1', reserved:'0', floorId: '9', apartmentTypeId: '1'},
  { id: '26',  name:'a_central', label: 'Apartman 2', price: '1100', sold:'1', reserved:'0', floorId: '9', apartmentTypeId: '2'},
  { id: '27',  name:'a_right',   label: 'Apartman 3', price: '1100', sold:'1', reserved:'0', floorId: '9', apartmentTypeId: '3'},

  { id: '28',  name:'a_left',    label: 'Apartman 4', price: '1200', sold:'1', reserved:'0', floorId: '10', apartmentTypeId: '1'},
  { id: '29',  name:'a_central', label: 'Apartman 5', price: '1200', sold:'1', reserved:'0', floorId: '10', apartmentTypeId: '2'},
  { id: '30',  name:'a_right',   label: 'Apartman 6', price: '1200', sold:'1', reserved:'0', floorId: '10', apartmentTypeId: '3'},

  { id: '31',  name:'a_left',    label: 'Apartman 7', price: '1300', sold:'1', reserved:'0', floorId: '11', apartmentTypeId: '1'},
  { id: '32',  name:'a_central', label: 'Apartman 8', price: '1300', sold:'1', reserved:'0', floorId: '11', apartmentTypeId: '2'},
  { id: '33',  name:'a_right',   label: 'Apartman 9', price: '1300', sold:'1', reserved:'0', floorId: '11', apartmentTypeId: '3'},

  { id: '34',  name:'a_left',    label: 'Apartman 10', price: '1400', sold:'1', reserved:'0', floorId: '12', apartmentTypeId: '4'},
  { id: '35',  name:'a_central', label: 'Apartman 11', price: '1400', sold:'1', reserved:'0', floorId: '12', apartmentTypeId: '5'},
  { id: '36',  name:'a_right',   label: 'Apartman 12', price: '1400', sold:'1', reserved:'0', floorId: '12', apartmentTypeId: '6'},


  { id: '37',  name:'a_left',    label: 'Apartman 1', price: '1100', sold:'0', reserved:'0', floorId: '13', apartmentTypeId: '1'},
  { id: '38',  name:'a_central', label: 'Apartman 2', price: '1100', sold:'0', reserved:'0', floorId: '13', apartmentTypeId: '2'},
  { id: '39',  name:'a_right',   label: 'Apartman 3', price: '1100', sold:'0', reserved:'0', floorId: '13', apartmentTypeId: '3'},

  { id: '40',  name:'a_left',    label: 'Apartman 4', price: '1200', sold:'0', reserved:'0', floorId: '14', apartmentTypeId: '1'},
  { id: '41',  name:'a_central', label: 'Apartman 5', price: '1200', sold:'0', reserved:'0', floorId: '14', apartmentTypeId: '2'},
  { id: '42',  name:'a_right',   label: 'Apartman 6', price: '1200', sold:'0', reserved:'0', floorId: '14', apartmentTypeId: '3'},

  { id: '43',  name:'a_left',    label: 'Apartman 7', price: '1300', sold:'0', reserved:'0', floorId: '15', apartmentTypeId: '1'},
  { id: '44',  name:'a_central', label: 'Apartman 8', price: '1300', sold:'0', reserved:'0', floorId: '15', apartmentTypeId: '2'},
  { id: '45',  name:'a_right',   label: 'Apartman 9', price: '1300', sold:'0', reserved:'0', floorId: '15', apartmentTypeId: '3'},

  { id: '46',  name:'a_left',    label: 'Apartman 10', price: '1400', sold:'0', reserved:'0', floorId: '16', apartmentTypeId: '4'},
  { id: '47',  name:'a_central', label: 'Apartman 11', price: '1400', sold:'0', reserved:'0', floorId: '16', apartmentTypeId: '5'},
  { id: '48',  name:'a_right',   label: 'Apartman 12', price: '1400', sold:'0', reserved:'0', floorId: '16', apartmentTypeId: '6'},


  { id: '49',  name:'a_left',    label: 'Apartman 1', price: '1100', sold:'0', reserved:'0', floorId: '17', apartmentTypeId: '1'},
  { id: '50',  name:'a_central', label: 'Apartman 2', price: '1100', sold:'0', reserved:'0', floorId: '17', apartmentTypeId: '2'},
  { id: '51',  name:'a_right',   label: 'Apartman 3', price: '1100', sold:'0', reserved:'0', floorId: '17', apartmentTypeId: '3'},

  { id: '52',  name:'a_left',    label: 'Apartman 4', price: '1200', sold:'0', reserved:'0', floorId: '18', apartmentTypeId: '1'},
  { id: '53',  name:'a_central', label: 'Apartman 5', price: '1200', sold:'0', reserved:'0', floorId: '18', apartmentTypeId: '2'},
  { id: '54',  name:'a_right',   label: 'Apartman 6', price: '1200', sold:'0', reserved:'0', floorId: '18', apartmentTypeId: '3'},

  { id: '55',  name:'a_left',    label: 'Apartman 7', price: '1300', sold:'0', reserved:'0', floorId: '19', apartmentTypeId: '1'},
  { id: '56',  name:'a_central', label: 'Apartman 8', price: '1300', sold:'0', reserved:'0', floorId: '19', apartmentTypeId: '2'},
  { id: '57',  name:'a_right',   label: 'Apartman 9', price: '1300', sold:'0', reserved:'0', floorId: '19', apartmentTypeId: '3'},

  { id: '58',  name:'a_left',    label: 'Apartman 10', price: '1400', sold:'0', reserved:'0', floorId: '20', apartmentTypeId: '4'},
  { id: '59',  name:'a_central', label: 'Apartman 11', price: '1400', sold:'0', reserved:'0', floorId: '20', apartmentTypeId: '5'},
  { id: '60',  name:'a_right',   label: 'Apartman 12', price: '1400', sold:'0', reserved:'0', floorId: '20', apartmentTypeId: '6'},


  { id: '61',  name:'a_left',    label: 'Apartman 1', price: '1100', sold:'0', reserved:'0', floorId: '21', apartmentTypeId: '1'},
  { id: '62',  name:'a_central', label: 'Apartman 2', price: '1100', sold:'0', reserved:'0', floorId: '21', apartmentTypeId: '2'},
  { id: '63',  name:'a_right',   label: 'Apartman 3', price: '1100', sold:'0', reserved:'0', floorId: '21', apartmentTypeId: '3'},

  { id: '64',  name:'a_left',    label: 'Apartman 4', price: '1200', sold:'0', reserved:'0', floorId: '22', apartmentTypeId: '1'},
  { id: '65',  name:'a_central', label: 'Apartman 5', price: '1200', sold:'0', reserved:'0', floorId: '22', apartmentTypeId: '2'},
  { id: '66',  name:'a_right',   label: 'Apartman 6', price: '1200', sold:'0', reserved:'0', floorId: '22', apartmentTypeId: '3'},

  { id: '67',  name:'a_left',    label: 'Apartman 7', price: '1300', sold:'0', reserved:'0', floorId: '23', apartmentTypeId: '1'},
  { id: '68',  name:'a_central', label: 'Apartman 8', price: '1300', sold:'0', reserved:'0', floorId: '23', apartmentTypeId: '2'},
  { id: '69',  name:'a_right',   label: 'Apartman 9', price: '1300', sold:'0', reserved:'0', floorId: '23', apartmentTypeId: '3'},

  { id: '70',  name:'a_left',    label: 'Apartman 10', price: '1400', sold:'0', reserved:'0', floorId: '24', apartmentTypeId: '4'},
  { id: '71',  name:'a_central', label: 'Apartman 11', price: '1400', sold:'0', reserved:'0', floorId: '24', apartmentTypeId: '5'},
  { id: '72',  name:'a_right',   label: 'Apartman 12', price: '1400', sold:'0', reserved:'0', floorId: '24', apartmentTypeId: '6'},
];