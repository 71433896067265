import React, { useState, useEffect } from 'react';
import './floorSelection.css';
import { floorsData, floorsTypeData } from './floorData';
import { buildingsData, buildingsTypeData } from '../buildingSelection/buildingData';
import { useLocation } from 'react-router-dom'
import {floorImages} from '../../imageData/imageData'
import { Link, useHistory } from "react-router-dom"


function FloorSelection() {
  const location = useLocation()
  const {buildingId} = location.state
  const buildingTypeId = buildingsData.find(item => item.id === buildingId).buildingTypeId
  const buildingType = buildingsTypeData.find(item => item.id === buildingTypeId)
  const buildingName = buildingsData.find(item => item.id === buildingId).label

  const filteredFloors = floorsData.filter(floor => floor.buildingId === buildingId);

  const floorsExtended = filteredFloors.map(floor => {
    const typeData = floorsTypeData.find(type => type.id === floor.floorTypeId);
    return {
      ...floor,
      typeName: typeData ? typeData.name : ''
    };
  });

  const [selected, setSelected] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedItemType, setSelectedItemType] = useState('');

  const handleItemHover = (itemId) => {
    setSelectedItem(floorsData.find(item => item.id === itemId));
    setSelectedItemType(floorsTypeData.find(item => item.id === (floorsData.find(item => item.id === itemId).floorTypeId)));
    setSelected(true);
  };

  const handleItemLeave = () => {
    setSelectedItem('');
    setSelectedItemType('');
    setSelected(false);
  };
  
  return (
    <>
      <div className="container">
        <div className="nav-object">
          <div className="sidebar">
            <div className="sidebar-title">
              <p>Izaberite sprat:</p>
            </div>
            <Link className="sidebar-selection" to="apartments" state={{ floorId: selectedItem.id }}>
                {floorsData
                  .filter(item => item.buildingId === buildingId)
                  .map(item => (
                   <div
                     key={item.name}
                     className={`sidebar-item ${selectedItem.id === item.id ? 'selected' : ''}`}
                     onMouseEnter={() => handleItemHover(item.id)}
                     onMouseDown={() => handleItemHover(item.id)}
                     onMouseLeave={() => handleItemLeave()}
                   >
                    <i className="fa-solid fa-layer-group" />
                    <p>{item.label}</p>
                   </div>
                  ))}
            </Link>
          </div>
          <div className="object-main-content">
            <div className="main-content-title">
              <h1>{selected ? selectedItem.name : buildingName}</h1>
            </div>
            <div className='render'>
              <div className='img-wrapper'>
                <img src={floorImages[buildingType.frontalImageName]} alt={'main'} className='main-image' />
              </div>
              {floorsExtended.map(floor => (
                floor.sold !== '0' ? (
                  <div className="img-wrapper">
                    <img src={floorImages[floor.typeName + '_red']} alt={floor.name + ' sold'} className="overlay-image" />
                  </div>
               ) : null
               ))}
               {floorsExtended.map(floor => (
                floor.sold === '0' && floor.reserved !== '0' ? (
                  <div className="img-wrapper">
                    <img src={floorImages[floor.typeName + '_yellow']} alt={floor.name + ' sold'} className="overlay-image" />
                  </div>
               ) : null
               ))}
              {selected && selectedItem.sold === '0' && selectedItem.reserved === '0' ? 
                <div className='img-wrapper'>
                  <img src={floorImages[selectedItemType.name + '_gray']} alt={selectedItem.name} className='overlay-image' />  
                </div> : ''}
            </div>
            <div className="legend">                
                <i className="fas fa-square" style={{ color: 'rgb(255, 0, 0)' }}></i>
                <p> prodato    </p>
                <i className="fas fa-square" style={{ color: 'rgb(255, 255, 0)' }}></i>
                <p> rezervisano </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FloorSelection;
