import React, { useCallback } from 'react';
import { FaFilePdf } from 'react-icons/fa';
import './pdfButton.css';

const PdfButton = ({ text, pdfPath }) => {
  const onButtonClick = useCallback(() => {
    // using JavaScript method to get PDF file
    fetch(pdfPath).then(response => {
      response.blob().then(blob => {
        // Creating a new object of the PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = text + '.pdf'; // Use a unique name, e.g., based on 'text'
        alink.click();
      });
    });
  }, [pdfPath, text]);

  return (
    <>
      <button onClick={onButtonClick} className="pdfButton">
        <p>{text}</p>
      </button>
    </>
  );
};

export default PdfButton;
