export const buildingsTypeData = [
  { id: '1', name: 'AB', frontalImageName: 'a_building', numberOfLevels: '4', numberOfApartments: '12', desciption: 'desc'},
  { id: '2', name: 'AB', frontalImageName: 'a_building', numberOfLevels: '4', numberOfApartments: '12', desciption: 'desc'},
];

export const buildingsData = [
    { id: '0',  name:'a_buildings', label: 'Kompleks objekata', sold: '0', reserved: '0'},

    { id: '1',  name:'a_building1', label: 'Objekat A1', sold: '0', reserved: '0', buildingTypeId: '1'},
    { id: '2',  name:'a_building2', label: 'Objekat A2', sold: '0', reserved: '0', buildingTypeId: '1'},
    { id: '3',  name:'a_building3', label: 'Objekat A3', sold: '1', reserved: '0', buildingTypeId: '1'},
    { id: '4',  name:'a_building4', label: 'Objekat B1', sold: '0', reserved: '0', buildingTypeId: '2'},
    { id: '5',  name:'a_building5', label: 'Objekat B2', sold: '0', reserved: '0', buildingTypeId: '2'},
    { id: '6',  name:'a_building6', label: 'Objekat B3', sold: '0', reserved: '0', buildingTypeId: '2'}
  ];
