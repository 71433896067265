import React from 'react';

function MapComponent({ lat, lng }) {
  return (
    <div style={{ width: '80%', height: '70vh', margin: '0 auto' }}>
      <iframe
        width="100%"
        height="100%"
        style={{ border: 0 }}
        src={`https://maps.google.com/maps?q=${lat},${lng}&hl=en&z=13&output=embed`} 
        allowFullScreen
      />
    </div>
  );
}

export default MapComponent;
