import React, { useEffect }  from 'react'
import './about.css'

import Logo from "../../common/logo/Logo"
import CitationCard from './citationCard/CitationCard';

import { Link } from "react-router-dom"

import { heroImage } from '../../imageData/imageData';
import ArrowButton from '../../button/arrowButton/arrowButton';
import BlogHome from '../blog/blogHome/BlogHome';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const backgroundStyle = {
    backgroundImage: `url(${heroImage['hero']}`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '22%',
  };

  return (
    <>
      <section className='about'>
        <div className='introducer-background' style={backgroundStyle}>
          <div className='introducer'>
            <div className='introducer-text'>
              <p>Za najlepše trenutke na Kopaoniku</p>
              <h1>Mi gradimo Vaš apartman o kojem sanjate</h1>
              <div className='contact-button'>
                <Link to='/contact'>
                  <ArrowButton text={'KONTAKTIRAJTE NAS'}/>
                </Link>
              </div>
            </div>
            <Logo company={"rg"}/> 
          </div>
        </div>
        <div className='container'>
          <div className='why-us'>
            <h1 style={{textAlign:'center'}}>Zašto mi?</h1>
            <p>Započevši svoju misiju u 2021. godini, Royal Gradnja 2021 je rođena iz ljubavi prema lepotama Kopaonika i entuzijazmu za stvaranje domova koji odražavaju tu lepotu. Naša strast za izgradnjom ne samo objekata, već domova u kojima će se ljudi osećati povezano s prirodom, dala je život našim projektima i ostavila duboki trag na Kopaoniku.</p>
            <p>Zašto mi? Zato što je naša saradnja sa klijentima duboko ukorenjena u vrednostima koje nas odlikuju - poverenje, iskustvo i kompetentnost. Mi razumemo Vaše potrebe, Vaše snove i Vaše želje, i radimo sve što je u našoj moći da ih ostvarimo. Naša kompetentnost nije samo tehnička - ona je, pre svega, ljudska.</p>
            <p>Mi ne gradimo samo Vaš dom - mi izgrađujemo most između Vaših snova i stvarnosti. Zato, kada se pitate zašto biste nam dali svoje poverenje, odgovor je jednostavan: Royal Gradnja 2021 je više od građevinske firme - to je partner u ostvarivanju Vaših snova.</p>
          </div>
          <div className='successful-projects'>
            <h1 style={{textAlign:'center'}}>Uspešni projekti našeg tima</h1>
            <p>Dva uspešna projekta, u neposrednoj blizini novog dela kompleksa Royal Resort, svedoče o našem stalnom napretku i posvećenosti kvalitetu. Jedan od njih i predstavlja deo kompleksa Royal Resort izgrađen u prvoj fazi ovog projekta, i dočekaće prve stanare već u decembru 2023. godine. Oba kompleksa nisu samo zgrade - oni su simboli našeg neprestanog napora da obogatimo životne prostore naših klijenata. Članovi našeg tima postavili su temelje ovih nekretnina, gradeći ne samo zidove, već i poverenje i zadovoljstvo naših kupaca.</p>
            <BlogHome displayCount={2} blogIds={['1', '3']}/>                      
          </div>
          <div className='satisfied-customers'>
            <h1 style={{textAlign:'center'}}>Šta kupci naših apartmana kažu o nama?</h1>
            <div className='customers'>
              <CitationCard title={'Hvala vam'} text='Kupovina apartmana na Kopaoniku bila je velika odluka za nas, ali sa njima sve je bilo veoma jednostavno. Zahvalni smo timu na stručnom vođenju kroz ceo proces, što je olakšalo našu odluku. Oduševljeni smo našim novim domom i ne možemo dočekati da provedemo zimu na ovoj prelepoj planini.' name={'Ljubiša Nikolić'}/>
              <CitationCard title={'Kao kod kuće'} text='Nakon što smo prvi put posetili apartman koji smo kupili, bili smo oduševljeni potencijalom i kvalitetom planirane izrade. Sve je bilo savršeno osmišljeno, od podnog grejanja do modernog dizajna. Osećaj je takav da ćemo svaki trenutak koji budemo proveli tamo osećati kao da smo kod kuće, i to je zaista nešto posebno.' name={'Zoran Gogić'}/>
              <CitationCard title={'Ljudskost'} text='Od početka saradnje, bili smo impresionirani njihovom korektnošću i transparentnošću. Komunikacija je bila stalna i jasna, a sve dogovoreno je ispoštovano do najsitnijeg detalja. Ljudskost i profesionalizam kojima su pristupili našem slučaju učinili su ovu investiciju ne samo mudrom, već i prijatnom.' name={'Ljiljana Radenković'}/>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default About
