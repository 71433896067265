import React, { useEffect } from 'react';
import './contact.css'

import PageHeading from '../../common/pageHeading/PageHeading';
import { FaEnvelope, FaPhone, FaInstagram } from 'react-icons/fa';
import MapComponent from '../../common/googleLocation/GoogleLocation';

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
      <section className='contact'>
        <PageHeading title={"Kontaktirajte nas"} subtitle={"Kontaktirajte naš tim investitora i zakažite razgovor ili posetu gradilištu. Jedan od apartmana u ponudi može biti Vaš već danas!"}/>
        <div className='contact-options'>
          <div className='contact-info-box'>
            <div class="contact-circle">
              <a class="contact-icon" href="tel:+381648204041"><FaPhone/></a>
            </div>
            <h3>Pozovite nas</h3>
            <p>Pozivom na jedan od brojeva telefona ili klikom na ikonicu telefona iznad možete kontaktirati naš tim investitora i saznati dodatne informacije o projektu i kupovini apartmana.</p>
            <div className='contact-data'>
              <p>Broj telefona 1: +381648204041</p>
              <p>Broj telefona 2: +381648258160</p>
              <p>Broj telefona 3: +381642612484</p>
            </div>
          </div>
          <div className='contact-info-box'>
            <div class="contact-circle">
              <a class="contact-icon" href="mailto:royalgradnja2021@gmail.com"><FaEnvelope/></a>
            </div>
            <h3>Pošaljite email</h3>
            <p>Ukoliko imate pitanje za naš tim investitora ili predlog za saradnju, pošaljite nam email!</p>
            <p>Klikom na ikonicu pisma iznad možete kontaktirati naš tim.</p>
            <div className='contact-data'>
              <p className='contact-data'>Email adresa naše firme je royalgradnja2021@gmail.com</p>
            </div>
          </div>
          <div className='contact-info-box'>
            <div class="contact-circle">
            <a class="contact-icon" href="https://www.instagram.com/royal_resort_kopaonik/"><FaInstagram/></a>
            </div>
            <h3>Posetite nas na IG</h3>  
            <p>Posetite nas na društvenim mrežama i pratite najaktuelnije novosti sa našeg gradilišta!</p>
            <p>Podržite nas i zapratite na Instagramu i Fejbuku!</p>
            <div className='contact-data'>
              <p>Instagram: @royal_resort_kopaonik</p>
              <p>Facebook: Royal Resort Kopaonik</p>
            </div>
          </div>
        </div>
        <PageHeading title={"Pronađite nas na mapi"} subtitle={"Ukoliko ste zakazali posetu gradilištu, možete iskoristiti mapu sa ucrtanom lokacijom kako biste lako pronašli put do nas!"}/>
        <MapComponent lat={43.265024} lng={20.788910} />
      </section>
    </>
  );
}

export default Contact;