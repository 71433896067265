import React, {useEffect, useState} from 'react';
import './apartmentsOffer.css';
import apartmentImg from '../../../assets/arch_project/apartments/renders/a_central.png';
import SearchBar from './searchBar/SearchBar';
import { apartmentsTypeData } from '../apartmentSelection/apartmentData';
import { apartmentsData } from '../apartmentSelection/apartmentData';
import { floorsData } from '../floorSelection/floorData';
import { buildingsData } from '../buildingSelection/buildingData'
import ApartmentCard from './apartmentCard/ApartmentCard';
import { Link, useHistory } from "react-router-dom"
import PageHeading from '../../common/pageHeading/PageHeading';

const ApartmentsOffer = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
        }, []);

    //calbacks for retrieving data from search bar component

    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0);
    const [minSqft, setMinSqft] = useState(0);
    const [maxSqft, setMaxSqft] = useState(0);
    const [floor, setFloor] = useState(0);
    const [type, setType] = useState(0);

    const handleMinPriceChange = (newMinPrice) => {
        setMinPrice(newMinPrice);
    };
    
    const handleMaxPriceChange = (newMaxPrice) => {
        setMaxPrice(newMaxPrice);
    };

    
    const handleMinSqftChange = (newMinSqft) => {
        setMinSqft(newMinSqft);
    };
    
    const handleMaxSqftChange = (newMaxSqft) => {
        setMaxSqft(newMaxSqft);
    };

    const handleFloorChange = (newFloor) => {
        setFloor(newFloor);
    };
    
    const handleTypeChange = (newType) => {
        setType(newType);
    };

    //inner joining apartmentsData, apartmentsTypeData, floorData and Building Data
    const finalData = apartmentsData.filter(apartment => apartment.sold === '0' && apartment.reserved === '0')
    .map(apartment => {
        const floor = floorsData.find(floor => floor.id === apartment.floorId);
        const building = buildingsData.find(building => building.id === floor.buildingId);
        const apartmentType = apartmentsTypeData.find(apartmentType => apartmentType.id === apartment.apartmentTypeId);
      
        return {
          apartmentId: apartment.id,
          floorId: floor.id,
          floorLabel: floor.label,
          buildingId: building.id,
          buildingTypeId: building.buildingTypeId,
          buildingLabel: building.label,
          apartmentTypeLabel: apartmentType.label,
          apartmentTypeName: apartmentType.name,
          apartmentNumberOfRooms: apartmentType.numberOfRooms,
          apartmentTypeSquareFootage: apartmentType.squareFootage,
          price: apartment.price
        };
      });
    

    //function for filtering apartments

    const filteredApartments = finalData.filter(apartment => {
        const inOffer = apartment.buildingTypeId === '1'
        const priceMatch = (minPrice === 0 || Number(apartment.price) * Number(apartment.apartmentTypeSquareFootage) >= minPrice) &&
                           (maxPrice === 0 || Number(apartment.price) * Number(apartment.apartmentTypeSquareFootage) <= maxPrice);
        const sqftMatch = (minSqft === 0 || Number(apartment.apartmentTypeSquareFootage) >= minSqft) &&
                          (maxSqft === 0 || Number(apartment.apartmentTypeSquareFootage) <= maxSqft);
        const floorMatch = (floor===0 || apartment.floorLabel===floor);
        const typeMatch = (type===0 || apartment.apartmentNumberOfRooms===type)
        if(floor==='Any' && type==='Any')
        {
            return inOffer && priceMatch && sqftMatch;
        }
        else if (floor==='Any' && type!=='Any')
        {
            return inOffer && priceMatch && sqftMatch && typeMatch;
        }
        else if (floor!=='Any' && type==='Any')
        {
            return inOffer && priceMatch && sqftMatch && floorMatch;
        }

        return inOffer && priceMatch && sqftMatch && floorMatch && typeMatch;
      });


  return (
    <>
    <div className='apartmentsOffer'>
        <PageHeading title={'Izaberite apartman'} subtitle={'Izaberite apartman po Vašoj želji pomoću dostupnih filtera'}/>
        <SearchBar 
            onMinPriceChange={handleMinPriceChange}
            onMaxPriceChange={handleMaxPriceChange}
            onMinSqftChange={handleMinSqftChange}
            onMaxSqftChange={handleMaxSqftChange}
            onFloorChange={handleFloorChange}
            onTypeChange={handleTypeChange}
        ></SearchBar>
        <div className='container'>
            <h1>Rezultati pretrage</h1>
            <div className='apartmentCard-container'>
                {filteredApartments.map(apartment => (
                    <>
                    <Link to={`/apartment/${apartment.apartmentId}`}>                            
                        <ApartmentCard 
                            imageName={apartment.apartmentTypeName} 
                            type={apartment.apartmentTypeLabel}
                            floorLabel={apartment.floorLabel}
                            buildingLabel={apartment.buildingLabel}
                            squareFootage={apartment.apartmentTypeSquareFootage} >       
                        </ApartmentCard>
                    </Link>
                    </>
                ))}
            </div>
        </div>
    </div>
    </>
  );
};

export default ApartmentsOffer;