import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import './header.css'
import LogoInline from '../logo/LogoInline'

const Header = () => {

  const [navList, setNavList] = useState(false)

  return (
    <>
      <header>
        <LogoInline/>
        <div></div>
        <div className='toggle'>
          <button onClick={() => setNavList(!navList)}>
            {navList ? <i className='fa fa-caret-up'></i> : <i className='fa fa-caret-down'></i>}
          </button>
        </div>
        <div className='menu'>
          <ul className={navList ? "show" : "noshow"}>
              <li>
                <Link onClick={() => setNavList(false)} to="/">POČETNA</Link>
              </li>
              <li>
                <Link onClick={() => setNavList(false)} to="/about">O NAMA</Link>
              </li>
              <li>
                <Link onClick={() => setNavList(false)} to="/project">PROJEKAT</Link>
              </li>
              <li>
                <Link onClick={() => setNavList(false)} to="/search">PONUDA</Link>
              </li>
              <li>
                <Link onClick={() => setNavList(false)} to="/blog">BLOG</Link>
              </li>
              <li>
                <Link onClick={() => setNavList(false)} to="/contact">KONTAKT</Link>
              </li>
          </ul>
        </div>
      </header>
    </>
  )
}

export default Header