import React, {useEffect, useState} from 'react';
import './apartmentInfo.css';
import { apartmentsData, apartmentsTypeData } from '../apartmentSelection/apartmentData';
import { useLocation } from 'react-router-dom'
import {apartmentInfoImages} from '../../imageData/imageData'
import { Link, useHistory, useParams } from "react-router-dom"
import prodatoTag from '../../../assets/prodato.png'
import { floorsData } from '../floorSelection/floorData';
import { buildingsData } from '../buildingSelection/buildingData';

function ApartmentInfo() {
  useEffect(() => {
    window.scrollTo(0, 0);
    }, []);

  const { id } = useParams();
  const apartment = apartmentsData.find(item => item.id === id);
  const apartmentTypeId = apartment.apartmentTypeId;
  const apartmentType = apartmentsTypeData.find(item => item.id === apartmentTypeId);
  const floor = floorsData.find(item => item.id === apartment.floorId);
  const building = buildingsData.find(item => item.id === floor.buildingId);

  return (
    <>
      <div className="container">
        <div className='catalog'>
          <div className='apartment-main-content'>
            <div className='info-table'>
              <h1>{apartment.label}</h1>
              <p>Tip: {apartmentType.label}</p>
              <p>Sprat: {floor.label}</p>
              <p>Zgrada: {building.label}</p>
              <p>Površina: {apartmentType.squareFootage}m²</p>
              {building.buildingTypeId === '1' ? <h4>Cena bez pdv-a: {apartment.price}€/m²</h4> : <h4>Apartman nije u ponudi!</h4>}
            </div>
            <div className='apartment-sold-tag'>
                {apartment.sold !== '0' ? <img src={prodatoTag}></img> : ''}
            </div> 
            <div className='apartment-render'>
              <img src={apartmentInfoImages[apartmentType.name + '_render']}></img>   
            </div>
          </div>
          <div className='apartment-visuals'>
            <div className='position-visual'>
              <h2>Pozicija na spratu</h2>
              <img src={apartmentInfoImages[apartment.name + '_level_position']}></img>
            </div>
            <div className='rooms-visual'>
              <div className='rooms-table'>
                {apartmentType.id !== '2' && apartmentType.id !== '5'  ? 
                <div>
                  <h2>Spisak soba</h2>
                  <p>(1) HODNIK</p>
                  <p>(2) DNEVNA SOBA</p>
                  <p>(3) KUPATILO</p>
                  <p>(4) SOBA</p>
                </div> : 
                <div>
                  <h2>Spisak soba</h2>
                  <p>(1) KUPATILO</p>
                  <p>(2) DNEVNA SOBA</p>
                </div>}
              </div>
              <img src={apartmentInfoImages[apartment.name + '_rooms']}></img>
            </div>
          </div>     
        </div>
      </div>
    </>
  );
}

export default ApartmentInfo;
