import React from 'react'
import './logo.css'

import logo_rg_full from '../../../assets/logo/logo.svg'
import logo_junona from '../../../assets/logo/logo_junona.svg'

const Logo = ({company}) => {
  return (
    <>
      <div className='logo'>
        {company === 'rg' ? 
            <img src={logo_rg_full} alt='Logo Royal Gradnja 2021.' className='logo-rg-full'/> 
            : <img src={logo_junona} alt='Logo Agencija za nekretnine Junona' className='logo-junona'/>}
      </div>
    </>
  )
}
export default Logo
