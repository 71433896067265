import React from "react"
import "./hero.css"
import { Link } from "react-router-dom"
import ArrowButton from "../../button/arrowButton/arrowButton";

const Hero = ({ title, subtitle, cover, buttonText='Pogledajte ponudu' }) => {

  const heroStyles = {
    background: `linear-gradient(to top, rgba(0, 0, 0, 0), rgba(240, 243, 255, 0.0)), url(${cover})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '22%',
  };

  return (
    <>
      <section className='hero' style={heroStyles}>
        <div className='container'>
          <div className='heading'>
            <h1>{title}</h1>
            <p>{subtitle}</p>
            <Link to={'/project/buildings'}>
              <ArrowButton text={buttonText}/>
            </Link>
          </div>
        </div>
      </section>
    </>
  )
}

export default Hero
