import React from "react"
import { BrowserRouter as Router, Route } from "react-router-dom"
import { Routes  } from 'react-router-dom';
import { useRef } from "react";

import Header from '../common/header/Header'
import Footer from '../common/footer/Footer'

import Home from '../home/Home'
import About from './about/About'
import Contact from './contact/Contact'

import Project from './projects/Project'
import BuildingSelection from './buildingSelection/BuildingSelection'
import FloorSelection from './floorSelection/FloorSelection'
import ApartmentSelection from "./apartmentSelection/ApartmentSelection"
import ApartmentInfo from "./apartmentInfo/ApartmentInfo";

import Blog from "./blog/Blog";
import BlogDetails from "./blogDetails/BlogDetails";
import ApartmentsOffer from "./apartmentsOffer/ApartmentsOffer";

const Pages = () => {
  const myComponentRef = useRef(null);

  return (
    <>
      <Router>
        <Header myComponentRef={myComponentRef} />
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/about' element={<About/>} />
          <Route path='/contact' element={<Contact/>} />
          <Route path='/project' element={<Project/>} />
          <Route path='/project/buildings' element={<BuildingSelection/>} />
          <Route path='/project/buildings/floors' element={<FloorSelection/>} />
          <Route path='/project/buildings/floors/apartments' element={<ApartmentSelection/>} />
          <Route path='/apartment/:id' element={<ApartmentInfo/>} />
          <Route path='/blog' element={<Blog/>} />
          <Route path="/blogs/:id" element={<BlogDetails/>} />
          <Route path='/search' element={<ApartmentsOffer/>} />

        </Routes>

        <Footer/>
      </Router>
    </>
  )
}

export default Pages
