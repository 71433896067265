import React, { useState, useEffect } from "react"
import './buildingSelection.css';
import { buildingsData, buildingsTypeData } from './buildingData';
import { Link } from "react-router-dom"
import { buildingImages } from '../../imageData/imageData'

function BuildingSelection () {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [selectedItem, setSelectedItem] = useState(buildingsData[0].name);
  const selectedId = buildingsData.find(item => item.name === selectedItem).id

  const filteredBuildings = buildingsData.filter(building => building.buildingTypeId === '1');

  const handleItemHover = (itemName) => {
    setSelectedItem(itemName);
  };

  const handleItemLeave = () => {
    setSelectedItem(buildingsData[0].name);
  };

  return (
    <>
      <div className="buildings">
        <div className="container">
          <div className="nav-object">
            <div className="sidebar">
              <div className="sidebar-title">
                <p>Izaberite objekat:</p>
              </div>
              <Link className="sidebar-selection" to="floors" state={{ buildingId: selectedId }}>
                  {filteredBuildings.filter(item => item.name !== buildingsData[0].name).map(item => (
                    <div
                      key={item.name}
                      className={`sidebar-item ${selectedItem === item.name ? 'selected' : ''}`}
                      onMouseEnter={() => handleItemHover(item.name)}
                      onMouseDown={() => handleItemHover(item.name)}
                      onMouseLeave={() => handleItemLeave()}
                    >
                    <i className='fa-solid fa-building'></i>
                      <p>{item.label}</p>
                    </div>
                  ))}
              </Link>
            </div>
            <div className="object-main-content">
              <div className='main-content-title'>
                <h1>{buildingsData.find(item => item.name === selectedItem).label}</h1>
              </div>
              <div className="render">
                <div className="img-wrapper">
                  <img src={buildingImages['a_buildings']} alt={'main'} className='main-image' />
                </div>
                {buildingsData.map(building => (
                  building.sold !== '0' ? (
                    <div className="img-wrapper">
                      <img src={buildingImages[building.name + '_red']} alt={building.label + ' sold'} className="overlay-image" />
                    </div>
                ) : null
                ))}
                {buildingsData.map(building => (
                  building.sold === '0' && building.reserved !== '0' ? (
                    <div className="img-wrapper">
                      <img src={buildingImages[building.name + '_yellow']} alt={building.label + ' reserved'} className="overlay-image" />
                    </div>
                ) : null
                ))}
                {buildingsData.map(item => (
                   item.sold === '0' && item.reserved === '0' && selectedItem === item.name && selectedItem !== 'a_buildings' ? (
                    <div className="img-wrapper">
                      <img src={buildingImages[selectedItem + '_gray']} alt={selectedItem} className="overlay-image"/>
                    </div>
                  ) : null
                  ))} 
              </div>
              <div className="legend">                
                <i className="fas fa-square" style={{ color: 'rgb(255, 0, 0)' }}></i>
                <p> prodato    </p>
                <i className="fas fa-square" style={{ color: 'rgb(255, 255, 0)' }}></i>
                <p> rezervisano </p>
              </div>
            </div> 
                
          </div>
        </div>
      </div>
    </>
  );
  }

export default BuildingSelection;
