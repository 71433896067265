import React, { useEffect } from 'react';
import './blog.css';

import BlogHome from './blogHome/BlogHome';

const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BlogHome style='true'></BlogHome>
    </>
  );
};

export default Blog;
