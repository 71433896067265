const blogData = [
  { 
    id: '4',    
    title: 'Prve vesti sa novog gradilišta!', 
    date: '03/09/2023', 
    description: 'Na gradilištu Royal Resort kompleksa na Kopaoniku, svakodnevno se rađa budućnost luksuza i udobnosti. Sa zemljanim radovima i pripremom terena...',
    text: [
      "Na gradilištu Royal Resort kompleksa na Kopaoniku, svakodnevno se rađa budućnost luksuza i udobnosti. Sa zemljanim radovima i pripremom terena završenim u prvoj fazi, i nakon postavljenog temelja za prvu ploču, sada se radi na pripremi za drugu ploču koja će postati deo A dela kompleksa.",
      "Izvođači radova su posvećeni svakom detalju, korak po korak, kako bi postigli izvanredne rezultate. Njihova stručnost i strast su očigledni u svakom zahvatu. S obzirom na važnost ovog projekta, svaka faza se obavlja sa pažnjom i preciznošću.",
      "Svaki temelj, svaki betonski sloj, svaki čelični nosač - sve to gradi temelje ekskluzivnog iskustva koje će uskoro ponuditi Royal Resort kompleks. Sve ruke su usmerene ka istom cilju, a to je stvaranje mesta koje će spojiti prirodu, udobnost i luksuz."
    ]
  },
  { 
    id: '3',    
    title: 'Royal Resort - završni radovi', 
    date: '01/09/2023', 
    description: 'Uzbudljiva transformacija se odvija u okviru Royal Resort kompleksa na Kopaoniku, jer se završava poslednji dodir elegancije - fasada novog objekta...',
    text: [
      "Uzbudljiva transformacija se odvija u okviru Royal Resort kompleksa na Kopaoniku, jer se završava poslednji dodir elegancije - fasada novog objekta koji će blistati u svom sjaju. Ovaj objekat je prvi dragoceni deo slagalice koji čini celokupnu priču ovog ekskluzivnog odmarališta.",
      "Nakon uspešno završene izgradnje, fokus se sada prenosi na finalni estetski dodir. Fasada će ovom objektu dodati posebnu notu sofisticiranosti koja će se savršeno uklopiti sa okolinom. Inspirisan prirodnim lepotama Kopaonika, dizajn fasade će pričati svoju priču - o harmoniji sa planinskim pejzažem i udobnosti koju pruža svaki apartman unutar objekta.",
      "Ovaj poslednji korak u sređivanju objekta predstavlja završetak jednog poglavlja i početak drugog. Očekivanja su visoka, jer fasada neće samo služiti kao estetski dodatak, već će dodatno ojačati celokupni utisak luksuza i ekskluzivnosti koju Royal Resort nudi svojim posetiocima."
    ]
  },
  { 
    id: '2',    
    title: 'Izgradnja je počela!', 
    date: '30/08/2023', 
    description: 'Uzbudljive vesti su stigle za ljubitelje prirode i udobnosti - izgradnja A dela kompleksa Royal Resort počinje danas...',
    text: [
      "Uzbudljive vesti su stigle za ljubitelje prirode i udobnosti - izgradnja A dela kompleksa Royal Resort počinje danas! ",
      "Royal Resort predstavlja prefinjeni kompleks koji obuhvata šest objekata sa ukupno 72 apartmana. Ovaj ekskluzivni kompleks je podeljen u dve celine, gde prva tri objekta formiraju A deo kompleksa, dok preostala tri objekta čine B deo. U trenutnoj ponudi se nalaze apartmani iz A dela kompleksa, dok će izgradnja B dela započeti nakon završetka A dela kompleksa.",
      "Smestivši se blizu vrha Treska, Royal Resort će pružiti nezaboravne poglede na planinske pejzaže koji će vas ostaviti bez daha. U svakom apartmanu, očekujte zadivljujuće panoramske prizore koji će vam pružiti istinsku vezu sa prirodom.",
      "Da podsetimo, ovo je druga faza već započetog projekta. Prva faza donela nam je objekat koji će svoje stanare primiti u decembru 2023. godine. Zajedno uz Gencian Resort, novi objekti predstavljaju već sada dokazanu investiciju.",
      "Royal Resort ne privlači samo one koji traže savršen odmor, već i pametne investitore koji prepoznaju priliku za prosperitet. Budućnost nikada nije izgledala svetlije, a ova ekskluzivna destinacija obećava spoj luksuza, prirode i održive vrednosti."
    ]
  },
  { 
    id: '1',
    title: 'Gencian Resort u punom sjaju', 
    date: '20/08/2023', 
    description: 'U blizini vrha Treska, na našem prelepom Kopaoniku, Gencian Resort zaista sija punim sjajem. Gencian Resort je primio svoje stanare...',
    text: [
      "U blizini vrha Treska, na našem prelepom Kopaoniku, Gencian Resort zaista sija punim sjajem. Gencian Resort je primio svoje stanare i prve posetioce u decembru 2022. godine. Moderan objekat sa šest nivoa donosi novo iskustvo boravka na planini, svojim gostima nudi udoban smeštaj, panoramske poglede na planinski pejzaž i priliku da se uživa u prirodnim čarima tokom svih godišnjih doba. ",
      "Kupci su prepoznali potencijal ove lokacije, kvalitet gradnje i našu posvećenost, tako da su svi apartmani u ovom objektu bili prodati još u izgradnji. Nakon prve provedene zime u novim apartmanima, utisci su samo još bolji..."]
  }
];

export default blogData;