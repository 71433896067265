import React, {useEffect} from 'react';
import './blogDetails.css';

import blogData from '../blog/blogHome/blogData';

import { useParams } from 'react-router-dom';
import Carousel from '../../common/carousel/Carousel';

const BlogDetails = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const { id } = useParams();
    const blog = blogData.find(blog => blog.id === id);

  return (
    <>
      <div className='container'>
          <div className='blog-heading'>
            <h2>{blog.title}</h2>
            <h4>{blog.date}</h4>
          </div>
          <div className='distancer-line'></div>
          <div className='blog-text'>
            {blog.text.map((paragraph, index) => (
              <p key={index}>
                {paragraph}
              </p>
            ))}
          </div>
          <Carousel blogId={blog.id}></Carousel>
      </div>
    </>

  );
};

export default BlogDetails;
