import React from 'react';
import './blogHome.css';
import BlogCard from '../blogCard/BlogCard';
import blogData from './blogData';
import PageHeading from '../../../common/pageHeading/PageHeading';

const BlogHome = (props) => {
  const { displayCount, blogIds } = props;

  // If blogIds are provided, filter the data, else use the full list.
  const blogsToShow = blogIds 
    ? blogData.filter(blog => blogIds.includes(blog.id))
    : blogData;

  return (
    <div className='blogHome'>
      {!displayCount && (
        <PageHeading
          title={'Royal Gradnja 2021 - blog'}
          subtitle={'Pratite najnovije vesti o izgradnji naših objekata na Kopaoniku i druge zanimljivosti na našem blogu'}
        />
      )}
      <div className='container'>
        <div className="blog-grid">
          {blogsToShow.slice(0, displayCount || undefined).map((blog) => (
            <BlogCard
              title={blog.title}
              date={blog.date}
              description={blog.description}
              id={blog.id}
              key={blog.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogHome;
