import React, { useState } from 'react';
import './apartmentSelection.css';
import { apartmentsData, apartmentsTypeData } from './apartmentData.js';
import { floorsData, floorsTypeData } from '../floorSelection/floorData';
import { useLocation } from 'react-router-dom'
import {apartmentImages} from '../../imageData/imageData'
import { Link, useHistory } from "react-router-dom"

function ApartmentSelection () {
  const location = useLocation()
  const {floorId} = location.state
  const floorTypeId = floorsData.find(item => item.id === floorId).floorTypeId
  const floorType = floorsTypeData.find(item => item.id === floorTypeId)

  const apartments = apartmentsData.filter(item => item.floorId === floorId)

  const apartmentsExtended = apartments.map(apartment => {
    const typeData = apartmentsTypeData.find(type => type.id === apartment.apartmentTypeId);
    return {
      ...apartment,
      typeName: typeData ? typeData.name : ''
    };
  });

  const [selected, setSelected] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedItemType, setSelectedItemType] = useState('');

  const handleItemHover = (itemId) => {
    setSelectedItem(apartmentsData.find(item => item.id === itemId));
    setSelectedItemType(apartmentsTypeData.find(item => item.id === apartmentsData.find(item => item.id === itemId).apartmentTypeId));
    setSelected(true);
  };

  const handleItemLeave = () => {
    setSelectedItem('');
    setSelectedItemType('');
    setSelected(false);
  };
  
  return (
    <>
      <div className="container">
        <div className="nav-object">
          <div className="sidebar">
            <div className="sidebar-title">
              <p>Izaberite apartman:</p>
            </div>
            <div>
              <Link className='sidebar-selection apartments-selection' to={`/apartment/${selectedItem.id}`}>
                {apartmentsData
                  .filter(item => item.floorId === floorId)
                  .map(item => (
                    <div
                      key={item.name}
                      className={`sidebar-item ${selectedItem.id === item.id ? 'selected' : ''}`}
                      onMouseEnter={() => handleItemHover(item.id)}
                      onMouseDown={() => handleItemHover(item.id)}
                      onMouseLeave={() => handleItemLeave()}
                    >
                      <i className="fa-solid fa-square" />
                      <p>{item.label}</p>
                    </div>
                  ))}
              </Link>
            </div>
          </div>
          <div className="object-main-content">
            <div className="main-content-title">
              <h1>{selected ? selectedItem.label : floorsData.find(item => item.id === floorId).name}</h1>
            </div>
            <div className='render'>
              <div className='img-wrapper'>
                <img src={apartmentImages[floorType.frontalImageName + "_render"]} alt={'main'} className='main-image' />
              </div>
              {apartmentsExtended.map(apartment => (
                apartment.sold !== '0' ? (
                  <div className="img-wrapper">
                    <img src={apartmentImages[apartment.typeName + '_hover_red']} alt={apartment.name + ' sold'} className="overlay-image" />
                  </div>
               ) : null
               ))}
               {apartmentsExtended.map(apartment => (
                  apartment.sold === '0' && apartment.reserved !== '0' ? (
                    <div className="img-wrapper">
                      <img src={apartmentImages[apartment.typeName + '_hover_yellow']} alt={apartment.name + ' reserved'} className="overlay-image" />
                    </div>
                  ) : null
                ))}
                {selected && selectedItem.sold === '0' && selectedItem.reserved === '0' ? 
                  <div className='img-wrapper'>
                    <img src={apartmentImages[selectedItemType.name + "_hover_gray"]} alt={selectedItem.name + ' selected'} className='overlay-image'/> 
                  </div> : ''}
            </div>
            <div className="legend">                
              <i className="fas fa-square" style={{ color: 'rgb(255, 0, 0)' }}></i>
              <p> prodato    </p>
              <i className="fas fa-square" style={{ color: 'rgb(255, 255, 0)' }}></i>
              <p> rezervisano </p>
            </div>
          </div>
        </div>
      </div>
   </>
  );
}

export default ApartmentSelection;
