import React from 'react';
import './arrowButton.css';

const ArrowButton = ({ text }) => {
  return (
    <div className='arrow-button'>
      <button>
        <i class="fa-solid fa-arrow-right"></i> 
        {text}
      </button>
    </div>
  );
};

export default ArrowButton;
