import React from 'react';
import { FaFacebook, FaInstagram, FaPhone, FaEnvelope } from 'react-icons/fa';
import "./footer.css"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-links">
          <h4 className="footer-title">ZAPRATITE NAS</h4>
          <ul className="social-links">
            <li>
              <a href="https://www.facebook.com/profile.php?id=61550651685441" target="_blank" rel="noopener noreferrer">
                <FaFacebook />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/royal_resort_kopaonik/" target="_blank" rel="noopener noreferrer">
                <FaInstagram />
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-links">
          <h4 className="footer-title">KONTAKTIRAJTE NAS</h4>
          <ul className="contact-info">
            <li>
              <a className="contact-line" href="tel:+381648204041">
                <i className="fas fa-phone"></i>
                <div>Royal Gradnja 2021</div>
              </a>
            </li>
            <li>
              <a className="contact-line" href="mailto:royalgradnja2021@gmail.com">
                <i className="fas fa-envelope"></i>
                <div>Royal Gradnja 2021</div>
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-links">
          <h4 className="footer-title">KORISNE STRANICE</h4>
          <ul className="useful-links">
            <li>
              <a href="/project">PROJEKAT</a>
            </li>
            <li>
              <a href="/search">PONUDA</a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-line"></div>
      <div className="copyright">Copyright © {new Date().getFullYear()} Royal Gradnja 2021. All rights reserved.</div>
    </footer>
  );
};

export default Footer;
