import React from 'react';
import './apartmentCard.css';
import {apartmentInfoImages} from '../../../imageData/imageData'
import { Link } from 'react-router-dom'

const ApartmentCard = ({ imageName, type, floorLabel, buildingLabel, squareFootage }) => {
  return (
    <div className="apartmentCard">
      <div className="apartmentCard-image" style={{ backgroundImage: `url(${apartmentInfoImages[imageName + '_render']})` }} />
      <div className="apartmentCard-content">
        <div className="type">
          <div className="title">
            Tip: 
          </div>
          <div className="value">
            {type}
          </div>
        </div>
        <div className='building'>
          <div className="title">
            Zgrada: 
          </div>
          <div className="value">
            {buildingLabel}
          </div>
        </div>
        <div className="floor">
          <div className="title">
            Sprat: 
          </div>
          <div className='value'>
            {floorLabel}
          </div>
        </div>
        <div className="sqft">
          <div className="title">
            Kvadratura: 
          </div>
          <div className='value'>
            {squareFootage}m²
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApartmentCard;
