import React from 'react'
import './logoInline.css'

import logo_rg_inline from '../../../assets/logo/logo_inline_2021.svg'

const LogoInline = () => {
  return (
    <>
      <div className='logo-inline'>
        <img src={logo_rg_inline} alt='Inline Logo' />
      </div>
    </>
  )
}
export default LogoInline
