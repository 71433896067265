import React, { useState ,useEffect} from 'react';
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';
import './carousel.css'

import { blogDetailsImages } from '../../pages/blogDetails/blogDetailsImageData';

const Carousel = ({ blogId }) => {
  const [current, setCurrent] = useState(0);

  const blogImages = blogDetailsImages[blogId];

  const images = Object.keys(blogImages);
  const length = images.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(images) || images.length <= 0) {
    return <div></div>;
  }

  return (
    <div className='slider-container'>
      <section className='slider'>
        <FaArrowAltCircleLeft className='left-arrow' onClick={prevSlide} />
        <FaArrowAltCircleRight className='right-arrow' onClick={nextSlide} />
        {images.map((slide, index) => {
          return (
            <div
              className={index === current ? 'slide active' : 'slide'}
              key={index}
            >
              {index === current && (
                <img src={blogImages[slide]} alt='blog image' className='image'/>
              )}
            </div>
          );
        })}
      </section>
    </div>  
  );
};

export default Carousel;
