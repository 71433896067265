import React from "react";
import "./homeParagraph.css";
import ArrowButton from '../../button/arrowButton/arrowButton';
import { Link } from "react-router-dom";

const HomeParagraph = ({ title, text, buttonText, linkTo, left }) => {
  const textAlignStyle = left ? 'left' : 'right';
  const marginLeftStyle = left ? '0' : '5%';
  const marginRightStyle = left ? '5%' : '0';

  return (
    <>
      <div className='container'>
        <section className='home-paragraph' style={{ textAlign: textAlignStyle, marginLeft: marginLeftStyle, marginRight: marginRightStyle }}>
            <h2 style={{ textAlign: textAlignStyle }}>{title}</h2>
            <p style={{ textAlign: textAlignStyle }}>{text}</p>
            <Link to={linkTo}>
              <ArrowButton text={buttonText}/>
            </Link>
        </section>
      </div>
    </>
  )
}

export default HomeParagraph
