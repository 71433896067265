import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import './infoButton.css';
import { Link } from "react-router-dom"

const InfoButton = ({ text, link }) => {
  return (
    <Link className='infoButton-link' to={link}>
      <button className="infoButton">
        <p>{text}</p>
      </button>
    </Link>
  );
};

export default InfoButton;
