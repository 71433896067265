import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import './project.css'
import HomeParagraph from "../../home/homeParagraph/homeParagraph";
import { heroImage } from "../../imageData/imageData";
import PdfButton from "../../button/pdfButton/pdfButton";

const Project = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HomeParagraph title={"Informacije o projektu Royal Resort"} text={"Upoznajte Royal Resort: Ekskluzivan kompleks koji se sastoji od 6 objekata i 72 apartmana. Objekti su spojeni u dve celine, pa tako prva tri objekta (posmatrano sa leve strane) čine A deo kompleksa, dok druga tri objekta čine B deo. Trenutno u ponudi nalaze se samo apartmani iz A dela kompleksa, dok će gradnja B dela početi nakon što se izgradnja A dela kompleksa završi. Kompleks je savršeno smešten na padinama Kopaonika, nedaleko od vrha Treska. U svakom apartmanu uživajte u zadivljujućim panoramskim pogledima koji će Vas ostaviti bez daha. Ali, to nije sve - u neposrednoj blizini nalaze se dva već izgrađena objekta, čime se dodatno potvrđuje potencijal ove lokacije kao pametne investicije koja obećava rastuću vrednost. Royal Resort privlači pametne investitore koji prepoznaju priliku za prosperitet. Vaša budućnost nikada nije izgledala svetlije."} buttonText={"IZABERITE SVOJ APARTMAN"} linkTo={'/project/buildings'} left={true}/>
      <div className="hero2-image">
        <img src={heroImage['hero2_skewed']}></img>
      </div>
      <div className="container">
        <div className="project-details">
          <h1>Detaljne informacije - objekti A1, A2 i A3</h1>
          <div className="project-details-table">
            <div className="detail-info-row">
              <i class="fa-solid fa-play"></i>
              <p>Građevinska dozvola objekta A1: ROP-RAS-5572-CPIH-6/2023 br. 351-89/23 od 02.08.2023.</p>
            </div>
            <div className="detail-info-row">
              <i class="fa-solid fa-play"></i>
              <p>Građevinska dozvola objekta A2: ROP-RAS-5580-CPIH-6/2023 br. 351-90/23 od 02.08.2023.</p>
            </div>
            <div className="detail-info-row">
              <i class="fa-solid fa-play"></i>
              <p>Građevinska dozvola objekta A3: ROP-RAS-5582-CPIH-6/2023 br. 351-91/23 od 02.08.2023.</p>
            </div>
            <div className="detail-info-row">
              <i class="fa-solid fa-play"></i>
              <p>Investitor: ROYAL GRADNJA 2021 doo iz Raške, ul. Ibarska bb, PIB 112793739</p>
            </div>
            <div className="detail-info-row">
              <i class="fa-solid fa-play"></i>
              <p>Početak izvođenja radova: 30. avgust 2023.</p>
            </div>
            <div className="detail-info-row">
              <i class="fa-solid fa-play"></i>
              <p>Završetak izvođenja radova: 22. oktobar 2025.</p>
            </div>
            <div className="detail-info-row">
              <i class="fa-solid fa-play"></i>
              <p>Broj apartmana 36, Broj parking mesta 36</p>
            </div>
            <div className="detail-info-row">
              <i class="fa-solid fa-play"></i>
              <p>Ukupna bruto izgrađena površina 1200m²</p>
            </div>
          </div>
          <div className="pdf-buttons">
            <div className="pdf-button-container">
              <PdfButton text={'GRAĐEVINSKA DOZVOLA OBJEKAT 1'} pdfPath={'pdfs/1.pdf'}/>
            </div>
            <div className="pdf-button-container">
              <PdfButton text={'GRAĐEVINSKA DOZVOLA OBJEKAT 2'} pdfPath={'pdfs/2.pdf'}/>
            </div>
            <div className="pdf-button-container">
              <PdfButton text={'GRAĐEVINSKA DOZVOLA OBJEKAT 3'} pdfPath={'pdfs/3.pdf'}/>
            </div>
          </div>
          </div>
      </div>
    </>
  )
}

export default Project
